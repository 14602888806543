/* #02bca0; */
p {
  text-align: center;
  opacity: 1;
  color: #fff;
}
.portal_banner {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.banner-text {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.portal_banner h1 {
  font-size: 100px;
  color: #fff;
  font-weight: 600;
  transition: 0.5s;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.portal-button {
  /* Targets anchor tags with class "portal-button" */
  z-index: 1 !important;
  text-decoration: none;
  display: inline-block;
  font-weight: 700;
  color: #02bca0;
  border: 1px solid #02bca0;
  padding: 18px 36px;
  margin-top: 20px;
  margin-right: 30px;
  font-size: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.portal-button span {
  /* Targets any span element within "portal-button" */
  z-index: 1;
}

.portal-button::before {
  /* Targets the pseudo-element "::before" of "portal-button" */
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #02bca0;
  left: 0;
  top: 0;
  transition: 0.3s ease-in-out;
  z-index: -1;
}

.portal-button:hover {
  color: #fff;
}

.portal-button:hover::before {
  /* Targets "::before" pseudo-element of "portal-button" on hover */
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

#vid-1 {
  pointer-events: none;
  z-index: 0 !important;
  display: block;
  object-fit: fill;
  width: 100% !important;
  height: auto;
  border-radius: 10px;
  justify-content: center;
}

#project-title {
  text-align: center;
  border: #02bca0 fill 2px;
}

#statement-1 {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 50px;
}

.project-info p {
  color: #fff;
  font-weight: 300;
}

.project-info {
  padding-bottom: 50px;
  text-align: center;
}
#paragraph-1 {
  text-align: justify;
  width: 50%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  opacity: 60% !important;
  border-radius: 10px;
  max-width: 80%;
  padding: 15px;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}
#img-1 {
  width: 50%;
  height: auto;
  object-fit: contain;
  border-radius: 20px;
}

#statement-2 {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

#responsibilities {
  text-align: justify;
  width: 50%;
  margin: auto;
  line-height: 50px;
}

#responsibilities p {
  color: #fff !important;
  opacity: 60%;
}

#projects {
  text-align: center;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.react-multi-carousel__arrow--left,
.react-multi-carousel__arrow--right {
  background-color: #02bca0 !important; /* Change to your color */
}
.img-container img {
  border-radius: 15px;
  border: 2px solid;
  border-color: #02bca0;
}

.lotm-thanks {
  padding-top: 20px;
}

.lotm-h2 {
  color: #02bca0 !important;
  padding: 10px 0 10px 0;
}

.lotm-thanks-list {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(min-content, 1fr);
  padding-top: 30px;
  padding-left: 120px;
  margin: 0 auto;
  color: #fff;
  opacity: 0.6;
  list-style-type: none;
  text-align: left;
}

.lotm-thanks-list li {
  margin: 0; /* Set width to 50% for two items per row */
  padding-right: 15px; /* Add some padding for spacing */
  padding-bottom: 10px;
}

.lotm-thanks-list li::before {
  content: "•";
  color: #02bca0;
  font-weight: 1000;
  display: inline-block;
  width: 1em;
}
.portal-3 {
  width: 50%;
  height: 50%;
  border-radius: 15px;
}

.portal-4 {
  width: 50%;
  height: 50%;
  border-radius: 15px;
}

.portal-5 {
  width: 50%;
  height: 50%;
  border-radius: 15px;
}

.portal-paragraph-title {
  color: #02bca0 !important;
  font-size: 20px;
  padding: 20px 0px 20px 0px;
}

.pharm-h2 {
  color: #02bca0 !important;
}

.projects-bulleted-list {
  text-align: justify;
  width: 50%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  max-width: 80%;
  padding: 15px;
}

.info-bold {
  color: #02bca0 !important;
}

@media (max-width: 650px) {
  .portal-button {
    display: none;
  }
  .portal_banner h1 {
    font-size: 50px;
  }
  .lotm-thanks-list {
    width: 80%;
    font-size: 12px;
    padding-left: 30px !important;
  }
}

@media (max-width: 750px) {
  #paragraph-1 {
    width: 80%;
  }
  .portal-3 {
    width: 80%;
  }
  .portal-4 {
    width: 80%;
  }
  .portal-5 {
    width: 80%;
  }
  #responsibilities {
    width: 80%;
  }
  .projects-bulleted-list {
    width: 80%;
  }
}

@media (max-width: 1300px) {
  .lotm-thanks-list {
    padding-left: 0px;
  }
}
