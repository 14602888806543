p {
  color: #fff;
  opacity: 60%;
}

.banner {
  margin-top: 0;
  padding: 130px 0 100px 0;
  background-color: transparent;
  background-position: right;
  background-repeat: no-repeat;
  z-index: -1;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

#role-title {
  color: #02bca0 !important;
  font-size: 36px;
  padding-bottom: 30px;
}
.banner p {
  text-align: start;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  opacity: 60% !important;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
#header-img {
  margin-top: 10px;
  max-width: 60%;
  max-height: 60% !important;
  height: auto;
  position: relative;
  z-index: 1;
}

#header-img::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200%; /* Adjust the size of the halo as needed */
  height: 200%;
}
/* .banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
} */
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/* Projects CSS */

#projects-title {
  text-align: center;
}
