@font-face {
  font-family: "Montserrat";
  src: url("../../../src/assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("../../../src/assets/fonts/Poppins-Medium.ttf") format("truetype");
}

.about {
  display: grid;
  position: relative;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, 1fr);
  transition: 0.5 ease-in-out !important;
  /* margin-right: 300px; */
}
.about-img-container {
  grid-row: 1 / 4;
  grid-column: 2 / 5;
}
.about-img {
  /* max-height: 75vh;
  max-width: 70vh; */
  object-fit: contain;
  border-radius: 20px !important;
}

.about-content {
  grid-row: 1 / 4;
  grid-column: 5 / 9;
  padding-left: 30px;
  max-height: 761px;
  max-width: auto;
}
.about-content div {
  justify-content: space-around;
}

.education-container {
  grid-row: 1 / 4;
  grid-column: 3 / 9;
}

.education-img {
  display: none;
}

.nav-buttons {
  /* margin-left: 100px; /* Automatically aligns nav-links to the center */
  display: flex;
  justify-content: center;
  padding: 50px 0 100px 0;
}

.navbar-link {
  font-family: "Montserrat", sans-serif !important;
  color: white !important;
  font-size: 30px;
  margin: 0 10px;
  opacity: 0.75;
}
.navbar-link:hover {
  opacity: 1;
}
.navbar-link.active {
  color: #02bca0 !important;
  opacity: 1;
}

#title {
  text-align: center;
  font-size: 50px;
  opacity: 1;
  padding-top: 50px;
  padding-bottom: 10px;
}

.headshot {
  max-width: 100%;
  height: 50vh;
  width: auto;
  object-fit: contain;
  border-radius: 20px;
  margin-top: 10px;
  grid-row: 2 / 5;
  grid-column: 2 / 5;
}

/* #paragraph {
  padding-left: 50px;
  font-size: 20px;
  text-align: left;
  justify-self: left;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-left: 30px;
  /* margin-top: 90px; 
  transition: 0.3 ease-in;
  opacity: 1;
} */

.about-content p {
  justify-self: left;
  font-size: 20px;
}

.experience-title {
  font-size: 25px;
  color: #02bca0 !important;
  padding-bottom: 10px;
}

.about-paragraph {
  opacity: 60% !important;
  text-align: left;
}

.fade-in {
  animation: 0.3s ease-in-out; /* Adjust duration as needed */
}

.bold-body {
  font-weight: bold;
  color: white;
  opacity: 1 !important;
}

.education-h2 {
  color: #02bca0 !important;
  font-size: 24px;
}

.education-h3 {
  color: #02bca0 !important;
  font-size: 22px;
}

.education-paragraph {
  text-align: left;
  font-size: 18px !important;
  opacity: 0.6;
}

.education-bulleted-list {
  text-align: left;
  opacity: 0.6;
  font-size: 18px !important;
}

@media (max-width: 1400px) {
  .about {
    display: flex;
    flex-direction: column;
    grid-template-columns: initial;
    grid-template-rows: auto;
    margin: 30px;
  }
  .about-img {
    width: 60%;
    height: auto;
    margin: 0 auto;
  }

  .about-img-container {
    margin: 0 auto;
    text-align: center;
    padding: 30px;
  }

  .about-content div {
    width: 80%;
    max-height: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 650px) {
  .navbar-link {
    font-size: 18px;
  }
  .education-h2 {
    font-size: 22px;
  }
  .education-h3 {
    font-size: 20px;
  }
  .education-paragraph {
    font-size: 18px;
  }
  .education-bulleted-list {
    font-size: 18px;
  }
  .about-paragraph {
    font-size: 15px !important;
  }
  .experience-title {
    font-size: 20px;
  }
}

@media (max-width: 450px) {
  .about-content {
    padding-bottom: 200px;
  }
}
