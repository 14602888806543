/************ Footer Css ************/
.footer {
  padding: 100px 0 50px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #ffffff;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {
  text-align: justify;
  width: 25%;
  margin: auto;
  display: flex;
  padding-top: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.footer-logo {
  visibility: hidden;
}

@media (max-width: 750px) {
  .footer p {
    width: 80%;
  }
  .footer-logo {
    display: none;
  }
}
