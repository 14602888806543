/****** General CSS ******/

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #080808 !important;
  background-image: url("./assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  font-family: "Montserrat", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  color: #fff !important;
  opacity: 1;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}
p {
  opacity: 60%;
}
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}
